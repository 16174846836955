<template>
    <Dialog class='post-details-modal' v-model:visible="displayPost" @show="onShown" :modal="false" :draggable="false" :closable="true" :dismissableMask="true">
        <template #header>
            <div class='status-post-owner-info'>
               <div class="names-container">
                    <span class='full-name' >{{stringUtils.fullName(post.activity.actor.data)}}</span>
                    <span class='username'><span >@{{post.activity.actor.data.name}}</span>
                     | {{timeUtils.getTimeFromNow(post.activity.time)}}{{ (post.activity.revision_date ? ' | Edited '+timeUtils.getTimeFromNow(post.activity.revision_date) : '')}}
                    </span>
                    <span class="posted-in-span" >
                        {{ post.activity.group ? ' Posted in ' + post.activity.group.name : ''}}
                        {{ post.activity.groupFeed ? '- ' + post.activity.groupFeed.name : ''}}
                    </span>
                </div>
               
            </div>
        </template>
        
        <!-- style="height: calc(80vh - 154px);" -->
        <div class='post-body-content' >
            <div class='post-content-title' v-if="post.activity.title">
                <span class="material-symbols-outlined question-post" v-if="post.activity.verb.startsWith('question')">
                emoji_people
                </span>
                <span class="material-symbols-outlined motion-post" v-if="post.activity.verb.startsWith('motion')">
                campaign
                </span>
                {{post.activity.title}}
            </div>
            <template v-if="post.activity.verb.startsWith('motion')">
                <div class="trade-details-section">
                    <div class="trade-details-header">Trade Details</div>
                    <div class="trade-details-content">
                        Place <b v-if="mainTradeOrderObject.tradeOrderType === 'Market'">{{ mainTradeOrderObject.tradeType === 'Buy' ? 'Buy' : 'Sell'}}</b>
                            <b v-else>{{mainTradeOrderObject.tradeType}}</b> order for <b>{{ mainTradeOrderObject.quantity }}</b> units of <b>{{ mainTradeOrderObject.symbol }}</b> <span v-html="mainTradeOrderText"></span>
                            <p style="margin-top:8px;">
                            
                            <b>Portfolio: {{post.activity.motion.portfolioName}}</b><br>
                            <b>Account: {{post.activity.motion.accountName}}</b> </p>
                    </div>
                </div>
                
                <div class="motion-details-header">Motion Details</div>

                <div class="voting-deadline-section">
                    <span class="voting-deadline-header">Voting Deadline:</span> {{ post.activity.motion.deadline }} hours ( <b>{{ deadlineDate }}</b> Approx.)
                </div>
                <div class="summary-section">
                    <div class="summary-section-header">Summary:</div>
                    <ul>
                        <template v-for="point of [post.activity.motion.summaryPoint1,post.activity.motion.summaryPoint2,post.activity.motion.summaryPoint3]">
                            <li v-if="point" :key="point" v-html="point">
                                
                            </li>
                        </template>
                    </ul>
                </div>

                <div class="rationale-header">Detailed Rationale:</div>
            </template>
            
            <div :id="'postActualContent-' + post.activity.id" class='post-actual-content' v-html="getPostContent()"></div>

                <div class="motion-section" v-if="post.activity.verb.startsWith('motion')">
                    <span class="motion-status-text" v-if="[PASSED_MOTION_STATUS, VETOED_MOTION_STATUS, WITHDRAWN_MOTION_STATUS, FAILED_MOTION_STATUS].includes(post.activity.motion.motionStatus)">{{ motionStatusText }}</span>
                    <span class="motion-status-text" v-else-if="post.activity.motion.motionStatus === SUBMITTED_MOTION_STATUS && deadlineHasPassed">
                    Deadline has passed, voting is over.
                    </span>
                    <span class="motion-status-text" v-else>
                            Motion is open for voting.  <a href="#" @click="goToLogin()">Login</a> or <a href="#" @click="goToSignup()">Signup</a> to vote.
                    </span>
                    
                    
                </div>

            <div v-if="post.activity.mentioned_tags" style="margin-top:10px;">
                <template v-for="feedTag in post.activity.mentioned_tags" :key="feedTag">
                <a class="mention tag-mention"  data-mention-type="tag" :href="'/index.html#/tag/profile/'+feedTag.tagId"  >{{(feedTag.tag.startsWith("#") ? feedTag.tag : "#"+feedTag.tag)}}</a>
                &nbsp;
                </template>

            </div>
            
            
            
        </div>
                
        <template #footer>
            <!-- <div class='comment-footer'> -->
               <div class='activity-stats-bar'>
                <span class='likes-container'>
                    <i class="pi pi-thumbs-up"  />
                    <span class='likes-amount'  >{{ postUtils.hasLikeCount(post.activity.reaction_counts) ? post.activity.reaction_counts.like : '' }}</span>
                </span>
                <span class='comments-container'>
                    <i class="pi pi-comment"  />
                    {{ post.activity.num_comments ? '' + post.activity.num_comments : '' }}
                </span>
                
                <span class='looks-container' >
                    👀 {{(postUtils.hasLookCount(post.activity.reaction_counts) ?post.activity.reaction_counts.look : '')}}
                </span>
            </div>
            <!-- </div> -->
        </template>
        
        
        
    </Dialog>

    
</template>

<script>
import {SUBMITTED_MOTION_STATUS, WITHDRAWN_MOTION_STATUS, PASSED_MOTION_STATUS, FAILED_MOTION_STATUS, VETOED_MOTION_STATUS} from '../constants';

//import {SHARE_URL} from '../../common/config';
//import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import Dialog from 'primevue/dialog';
//import CommentEditor from './CommentEditor';
//import UserAvatar from '../profile/UserAvatar';
//import MoreOptionsMenu from '../menu/MoreOptionsMenu';
//import CommentList from './CommentList';
//import ImageDialog from '../feed/ImageDialog';
//import ConfirmModal from '../modal/ConfirmModal.vue';
//import SharePostModal from '../modal/SharePostModal.vue';
//import ReactionMenu from '../menu/ReactionMenu';
//import PostLikesListDialog from './PostLikesListDialog.vue';
//import PostLocationsDialog from './PostLocationsDialog.vue';
//import FollowButton from '../button/FollowButton.vue';
//import Badge from 'primevue/badge';
// import CommentCard from './CommentCard';
import FeedService from '../../FeedService';
import TimeUtils from '../../utilities/TimeUtils';
import PostUtils from '../../utilities/PostUtils';
import StringUtils from '../../utilities/StringUtils';
import FormattingUtils from '../../utilities/FormattingUtils';
//import UserUtils from '../../utilities/UserUtils';
import BrowserUtils from '../../utilities/BrowserUtils';
//import { markRaw } from 'vue';
//import ClassicEditor from 'ckeditor5/packages/ckeditor5-build-classic/';
//import CKEditorPluginService from '../feed/CKEditorPluginsService';

//import GiphyDialog from './GiphyDialog.vue';

//import EventBus from '../../event-bus';
//import useVuelidate from '@vuelidate/core'
//import { maxValue } from '@vuelidate/validators';

//const LIKE = 1;
//const domParser = new DOMParser();
const MARKET_ORDER_TYPES = [BUY_MARKET_ORDER_NAME, SELL_MARKET_ORDER_NAME];

const BUY_MARKET_ORDER_NAME = 'Buy Market';
const SELL_MARKET_ORDER_NAME = 'Sell Market';
import { SIGNUP_URL, LOGIN_URL } from "../../config.js";

export default {
    components: {
        //InfiniteScroll,
        Dialog,
        //CommentEditor,
        //UserAvatar,
        //ImageDialog,
        //MoreOptionsMenu,
        //CommentList,
        //ConfirmModal,
        //GiphyDialog,
        //SharePostModal,
        //PostLikesListDialog,
        //PostLocationsDialog,
        //FollowButton,
        //Badge,
        //ReactionMenu
        // CommentCard
    },
    //emits: ['comment-posted', 'post-liked', 'post-unliked', 'post-deleted', 'post-reacted', 'post-unreacted'],
    props: {
        post: {
            type: Object,
            required: true,
        },
        
    },
     //setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading: false,
            message: '',
            noMoreResults: false,
            // change from 100vh to 80vh to fit mobile comments coverflow
            //scrollStyle: 'width: 100%; height: calc(80vh - 154px) ;',
            displayPost: false,
            postContent: '',

            comments: [],
            likeClicked: false,
            displayWait: false,
            commentLikes: [],

            timeUtils: null,
            postUtils: null,
            StringUtils: null,
            userUtils: null,
            mode: "read",
            
             updating: false,
            currentStatusLength: 0,
            readingStartTime: null,
            readingStopTime: null,
            postReactions: [],
            reactionCounts: {},
            MARKET_ORDER_TYPES,
            SUBMITTED_MOTION_STATUS,
            WITHDRAWN_MOTION_STATUS,
            FAILED_MOTION_STATUS,
            VETOED_MOTION_STATUS,
            PASSED_MOTION_STATUS,
            currencyFormatter: new FormattingUtils(),
        };
    },

    created() {
        this.timeUtils = TimeUtils;
        this.postUtils = PostUtils;
        this.stringUtils = StringUtils;
 

       
    },
    mounted() {
       
    },

    computed: {
        scrollStyle() {
            if( BrowserUtils.isMobile()){
                return 'width: 100%; height: 100%;';
            }
            else {
                return 'width: 100%; height: calc(80vh - 154px) ;';
            }
        },
        postMenuItems() {
            return this.getPostMenuItems();
        },

        mainTradeOrderObject() {
            return this.post.activity.verb.startsWith('motion') ? this.post.activity.motion.motionTradeOrders[0].tradeOrder : null;
        },

        mainTradeOrderText() {
            let text = '';

            if (this.post.activity.verb.startsWith('motion')) {

                if (this.mainTradeOrderObject.tradeOrderType === 'Market') {
                    text = `at <b>market price</b>`
                } else if (this.mainTradeOrderObject.tradeOrderType === 'Limit') {
                    text = `with a <b>limit price of ${this.currencyFormatter.format(this.mainTradeOrderObject.limitPrice,'$0.00')}</b>`;
                } else if (this.mainTradeOrderObject.tradeOrderType === 'Stop Limit') {
                    text = `with a <b>limit price of ${this.currencyFormatter.format(this.mainTradeOrderObject.limitPrice,'$0.00')}</b> and <b>stop price of ${this.currencyFormatter.format(this.mainTradeOrderObject.stopPrice,'$0.00')}</b>`;
                } else if (['Stop Loss', 'Stop Entry'].includes(this.mainTradeOrderObject.tradeOrderType)) {
                    text = `with a <b>stop price of ${this.currencyFormatter.format(this.mainTradeOrderObject.stopPrice,'$0.00')}</b>`;
                }
            }

            return text;
        },

        motionStatusText() {

            const MOTION_STATUS_MAP = {
                [PASSED_MOTION_STATUS]: 'Motion Passed',
                [WITHDRAWN_MOTION_STATUS]: 'Motion Withdrawn',
                [FAILED_MOTION_STATUS]: 'Motion Failed',
                [VETOED_MOTION_STATUS]: 'Motion Vetoed'
            }

            return MOTION_STATUS_MAP[this.post.activity.motion.motionStatus];
        },

        deadlineHasPassed() {
            return TimeUtils.isSameOrBeforeNow(this.post.activity.motion.deadlineDate);
        },

        deadlineDate() {
            return TimeUtils.getFormattedTime(this.post.activity.motion.deadlineDate, 'YYYY-MM-DD h:mm A');
        },
       
    },

  

    

    methods: {
        goToSignup() {
            window.location.href = SIGNUP_URL;
        },

        goToLogin() {
            window.location.href = LOGIN_URL;
        },

         gatherMentionsAndMedia() {
             // this.editMode.renderedEditor.editing.view.domConverter.

            let root = this.editMode.renderedEditor.model.document.getRoot();
            let mentions = [];
            let mediaUrls = [];
            for (let child of root.getChildren()) {
                //let p = this.editMode.renderedEditor.editing.view.domConverter.mapViewToDom(child);
                //console.log(JSON.stringify(child));
                PostUtils.gatherMentionsAndMedia(child, mentions, mediaUrls);
            }
            return { mentions: mentions, mediaUrls: mediaUrls };
        },

       
        

       

        

        isNote() {
            if( this.post && this.post.activity ) {
                return this.post.activity.has_note == 1;
            }
            else {
                return false;
            }
        },

        

       

        close() {
            this.displayPost = false;
            this.currentStatusLength = 0;
           
        },

        getPostContent() {
          const noteVersion = this.post.noteVersion;
          let content = null;

          if (noteVersion) {
            content = this.post.noteVersion.content;
          } else {
            if (this.post.activity.verb.startsWith('motion')) {
                content = this.post.activity.motion.details;
            } else {
                content = this.post.activity.content;
            }
          }

          if( noteVersion  && noteVersion.noteVersionTags.length ) {
             
              let tagSection = "<div><strong>Tags </strong>";
              noteVersion.noteVersionTags.forEach( tag => {
                  tagSection = tagSection + this.buildTag(tag) +"&nbsp;";
              });
              tagSection = tagSection+ "</div>"
              content = content + tagSection;
          }

          return content;
        },

        buildTag( tag ) {
            return '<a class="mention" href="/tag/profile/'+tag.tagId+'">#'+tag.name+'</a>';
        },

     
        

        

        

        onShown() {
            try {
                FeedService.recordPublicLook(this.post.activity);
            }catch(error){
                console.warn("error recording look...ignoring:", error);
            }
            let postActualContent = document.getElementById(`postActualContent-${this.post.activity.id}`);
            
            if (postActualContent) {

                // Array.from(postActualContent.getElementsByTagName('IMG')).forEach((img) => {
                //     img.addEventListener('click', () => {
                //         this.$refs.imageDialog.open(img.src, img.height > img.width);
                //     })
                // })

                // Array.from(postActualContent.getElementsByTagName('A')).forEach((link) => {
                //     if (link.hasAttribute('data-mention-type')) {
                //         link.addEventListener('click', () => {
                //             this.close();
                //         })
                //     }
                //     else {
                //         const href = link.getAttribute('href');
                        
                //         link.addEventListener('click', () => {
                //             if (href.startsWith(SHARE_URL)) {
                //                 this.close();
                //             }

                //             EventBus.emit('track-post-link-clicked', { postId: this.post.activity.foreign_id, href:link.getAttribute('href')});
                //         })
                //     }
                // })
            }

            this.likeClicked = false;
            this.comments = [];
            this.noMoreResults = false;
            this.loading = false;
           
            
            // this.loadMoreComments();
            // this.$nextTick(() => {
                
            //     if( this.startInEditMode ){
            //         this.enterEditMode();
            //     }else {
            //         EventBus.emit('track-view-post', this.post );
            //         this.startReadTimer();
            //     }
                
            // })

            
            
        },

       

       

        

       
    },

    name: 'PostDetailsDialog',
};
</script>

<style>

.post-details-modal {
    /* max-height: 100vh !important; */
    width: 700px;
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
}

.post-details-modal .p-dialog-header {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
    border-bottom: 1px solid #F2F2F2;
    padding: 16px 16px;
}



.post-details-modal .p-dialog-footer {
    border-top: 1px solid #F2F2F2;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
    padding: 16px;
}

/* .post-details-modal .p-dialog-header .p-dialog-header-icon {
    display: none;
} */

@media (max-width: 760px) {
    /* .comment-footer {
        width: auto !important;
    } */
    .comment-footer .p-avatar {
        align-self: flex-start;
        margin-top: 0.5rem;
        width: 36px;
        height: 36px;
        margin-right: 16px;
    }
    .post-details-modal {
        width: 100% !important;
        height: 100% !important; /*80vh;*/
        border-radius: 0px !important;
    }
    .post-details-modal .p-dialog-header {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }    
    .post-details-modal .p-dialog-footer {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 16px;
        width: 100% !important;
    }
    .post-details-modal .p-dialog-header .p-dialog-header-icon {
        display: block;
    }  
    
    .post-actual-content > figure {
        margin-inline-start: 0 !important;
    }

}

 
 


</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
::v-deep(*:not(.pi, .material-symbols-outlined)), ::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}

::v-deep(figure.table > table) {
    width: 100%;
}

::v-deep(p) {
    margin-top: 8px;
    margin: 0 0 1rem 0;
    line-height: 1.5;
}

.looks-container{
    margin-left: auto;
}

::v-deep(.embedly-card) {
    max-height: 680px;
    transform-origin: top center;
    -webkit-transform:scale(0.9);
    -moz-transform-scale:scale(0.9);
    }


 ::v-deep(.insta-iframe) {
     height:760px;
     
 }


 ::v-deep(.insta-blockquote){
     max-height: 500px;
     /* height: 630px; */
     transform-origin: top center;
     -webkit-transform:scale(0.7);
    -moz-transform-scale:scale(0.7);
 }

::v-deep(.ck.ck-media-form.ck-responsive-form){
    position: fixed;
    background: white;
    box-shadow: var(--ck-drop-shadow),0 0;
}

.post-details-modal .p-dialog-header .status-post-owner-info {
    display: flex;
    align-items: center;
    width: 100%;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container {
    margin-left: 8px;
    display: block;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .full-name {
    display: block;
    color: #32364e;
    font-size: 18px;
    font-weight: bold;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .full-name:hover {
    cursor: pointer;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .username {
    display: block;
    color: #a6a6a6;
    font-size: 12px;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .username span:hover {
    cursor: pointer;
}
.post-details-modal .p-dialog-header .status-post-owner-info .names-container .posted-in-span {
    color: #33CC99;
    font-size: 13px;
    cursor: pointer;
}
.post-details-modal .p-dialog-header .status-post-owner-info .more-options {
    margin-left: auto;
}

.post-details-modal .p-dialog-content .post-body-content {
    padding: 16px;
}
.post-details-modal .p-dialog-content .post-body-content .post-content-title {
    font-weight: bold;
    font-size: 24px;
    color: #32364e;
    padding-bottom: 16px;
}
.post-details-modal .p-dialog-footer .activity-stats-bar {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 16px 0;
}
.post-details-modal .p-dialog-footer .activity-stats-bar  [class*='container'] {
    display: flex;
    align-items: center;
    color: #32364e;
}
.post-details-modal .p-dialog-footer .activity-stats-bar  [class*='container'] i {
    font-size: 16px;
    margin-right: 8px;
}

.post-details-modal .p-dialog-footer .activity-stats-bar span.looks-container {
    font-size: 14px;
    margin-right: 8px;
}
.post-details-modal .p-dialog-footer .activity-stats-bar .likes-container {
    margin-right: 16px;
}
.post-details-modal .p-dialog-footer .activity-stats-bar .likes-container .likes-amount:hover,
.post-details-modal .p-dialog-footer .activity-stats-bar .likes-container i.liked,
.post-details-modal .p-dialog-footer .activity-stats-bar .likes-container i:not(.own-post):hover {
    cursor: pointer;
    color: #33CC99;
}
.post-details-modal .p-dialog-footer .activity-stats-bar .comments-container {
    margin-right: 16px;
}

.post-details-modal .p-dialog-content .p-scrollpanel ::v-deep(.p-scrollpanel-content) {
    overflow: auto;
    width: 100%;
    height: 100%;
}
.post-details-modal .p-dialog-content .p-scrollpanel ::v-deep(.p-scrollpanel-content::-webkit-scrollbar) {
    display: none;
}

.post-details-modal .p-dialog-footer .comment-footer ::v-deep(.post-comment-button) {
    margin: 0px;
}

.post-details-modal .p-dialog-content .comments-section {
    border-top: 2px solid #BFBFBF;
    padding: 16px;
}
.post-details-modal .p-dialog-footer .comment-footer {
    display: flex;
    align-items: center;
    /* width: 100%; */
}

.post-actual-content {
    word-wrap: break-word;
    font-size: 16px;
}
::v-deep(.post-actual-content p) {
    color: black;
}
::v-deep(.post-actual-content .image) {
    margin: 0 auto !important;
}

::v-deep(.post-actual-content .image.image-style-side) {
    float: right;
}
::v-deep(.post-actual-content .image img) {
    max-width: 100%;
    max-height: 100%;
    width: unset;
    height: unset;
}
::v-deep(.post-actual-content img:hover) {
    cursor: pointer;
}
::v-deep(.post-actual-content img) {         
    max-width: 100%;
    padding: 8px 0px;
}
::v-deep(a) {
    font-weight: bold;
}
::v-deep(.analyst-mention) {
    color: #693BF5;
}
::v-deep(.security-mention) {
    color: #33CC99;
}

::v-deep(.tag-mention) {
    color: #33CC99;
}
  

.more-options {
    float: right;
}

.p-avatar:hover {
    cursor: pointer;
}

.trade-details-section {
    margin-bottom: 24px;
    font-size: 14px;
}
.trade-details-section .trade-details-header {
    font-weight:bold;
    color: #32364e;
    font-size: 20px;
    margin-bottom: 16px;
}
.trade-details-section .trade-details-content ::v-deep(b),
.trade-details-section .trade-details-content b {
    color: black;
}

.motion-details-header {
    font-weight:bold;
    color: #32364e;
    font-size: 20px;
    margin-bottom: 16px;
}

.summary-section {
    margin-bottom: 20px;
    font-size: 14px;
}

.summary-section-header {
    font-weight: bold;
    color: #32364E;
    font-size: 14px;
    margin-bottom: 10px;
}
.summary-section ul {
    word-wrap: break-word;
    padding-inline-start: 40px;
}

.voting-deadline-section {
    margin-bottom: 16px;
    font-size: 14px;
}
.voting-deadline-section .voting-deadline-header {
    font-weight:bold;
    color: #32364e;
    
}
.voting-deadline-section ::v-deep(b) {
    color: black;
}

.rationale-header {
    font-weight: bold;
    color: #32364E;
    font-size: 14px;
    margin-bottom: 10px;
}

.motion-section {
    border-top: 1px solid #BFBFBF;
    margin: 24px 0;
    padding-top: 12px;
}

.motion-status-text {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: black;
}
.voted-text {
    color: black;
    font-size: 24px;
    font-weight: bold;
}
.voted-text ::v-deep(.yes) {
    color: #33CC99;
}
.voted-text ::v-deep(.no) {
    color: #E63E3E;
}

.motion-security-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}
.motion-security-section .security-exchange-text {
    font-weight: bold;
    margin-bottom: 12px;
}
.motion-security-section ::v-deep(.p-grid) {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
}
.motion-security-section ::v-deep(.p-grid > div:first-child) {
    padding-left: 8px;
}
.motion-security-section .security-table .label-container {
    text-align: left;
}
.motion-security-section .security-table .value-container {
    font-weight: bold;
}

.voting-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.voting-container button {
    display: flex;
    align-items: center;
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 8px;
    background: none;
}
.voting-container button:hover {
    transform:rotate(0.2deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
    animation-iteration-count: infinite;
    background: none;
    border-color: none;
    box-shadow: none;
}
.voting-container .yes-vote-button {
    margin-right: 12px;
}
.voting-container .yes-vote-button:hover {
    border-color: #33CC99;
}
.voting-container .no-vote-button:hover {
    border-color: #E63E3E;
}
.voting-container .vote-label {
    font-weight: bold;
    color: #32364e;
    font-size: 14px;
}
.voting-container .yes-vote-button .vote-icon {
    color: #33CC99;
}
.voting-container .no-vote-button .vote-icon {
    color: #E63E3E;
}
.voting-container .vote-icon {
    font-size: 24px;
}

/* .like--float {
    opacity: 0;
}
.up {
    margin-left: -5px;
    -webkit-animation: slide-out-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    99% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
} */
.cancel-post-button {
    /* font-size: 0.8rem; */
    /* padding: 5px; */
    padding: 0.4rem 0.8rem;
   margin-left: 5px;
    font-size: 12px;
     color: #32364e;
    background: #FFFFFF;
    border: 1px solid #32364e;
    border-radius: 500px;
    /* padding: 8px 64px; */
}

.update-post-button {
    
    /* font-size: 0.8rem; */
    /* padding: 5px; */
    padding: 0.4rem 0.8rem;
     margin-left: auto;
    font-size: 12px;
     color: #FFFFFF;
    background: #33CC99;
    border: 1px solid #33CC99;
    border-radius: 500px;
    /* padding: 8px 64px; */
    
}





.cancel-post-button:hover {
    color: #FFFFFF;
    background: #32364e;
    border-color: #32364e;
}
.cancel-post-button:focus {
    box-shadow: none;
    border-color: #32364e;
}


.update-post-button:hover {
    background: #00C083;
    border-color: #00C083;
}
.update-post-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}



.more-options-edit-mode {
    margin-left: 5px;
}

.status-container.input-error .ck-editor__editable_inline.ck-focused,
.status-container.input-error .ck.ck-content,
.status-container.input-error .ck.ck-toolbar {
  border-color: #E63E3E !important;
}

.actions-container {
    margin-left: auto;
    display: flex;
    align-items: center;
}
.actions-container > * {
    margin-right: 8px;
}
.actions-container > *:last-child {
    margin-right: 0px;
}

.following-badge {
    font-size: 14px;
    font-weight: normal;
}

.reaction-bar-container {
    display: flex;
    margin-bottom: 8px;
}
.reaction-icon {
    color: #32364e;
}
.reaction-icon:hover {
    cursor: pointer;
    transform:rotate(0.4deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
}
.reaction-container {
    margin-right: 4px;
}
.reaction-container:last-child {
    margin-right: 0;
}
.reaction-container > span:hover {
    cursor: pointer;
}
.reaction-container.own-post > span:hover {
    cursor: default;
    pointer-events: none;
}
.reaction-container.reacted .num {
    color: #33CC99;
}
/* Shaking animation */
@keyframes shakeError {
  0% {
    transform: translateX(0); }
  15% {
    transform: translateX(0.375rem); }
  30% {
    transform: translateX(-0.375rem); }
  45% {
    transform: translateX(0.375rem); }
  60% {
    transform: translateX(-0.375rem); }
  75% {
    transform: translateX(0.375rem); }
  90% {
    transform: translateX(-0.375rem); }
  100% {
    transform: translateX(0); } }

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
} 



::v-deep(.p-inputtext) {
    border-radius: 10px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    margin-right: 16px;
    padding: 8px 0px 8px 16px;
    flex: 1;
    color: #32364e;
    font-size: 18px;
    font-weight: bold;
}
::v-deep(.p-inputtext:hover) {
    border-color: #bfbfbf;
}
::v-deep(.p-inputtext:focus) {
    box-shadow: none;
    border-color: #bfbfbf;
}
::v-deep(.p-inputtext::placeholder) {
    color: #bfbfbf;
    font-size: 16px;
}

@media (max-width: 760px) {
    ::v-deep(.scroll-to-top-button) {
        bottom: 50px;
    }

    .post-details-modal {
    width: 100% !important;
    height: 100% !important;
    border-radius: 0px !important;
}
}

</style>
