import moment from "moment";

class TimeUtilsImpl {
    appendZ(time) {
        return time + 'Z';
    }

    getTimeFromNow(time) {
        let timeFromNow = '';

        const getTime = (time) => {
            return moment(time).fromNow();
        }

        if (typeof time === 'number') {
            timeFromNow = getTime(time);
        } else {
            if (time.endsWith('Z')) {
                timeFromNow = getTime(time);
            } else {
                timeFromNow = moment(this.appendZ(time)).fromNow();
            }
        }
        return timeFromNow
    }

    isSame(time, format) {
        const now = this.getFormattedTime(null, format);

        return moment(time).isSame(now);
    }

    isSameOrAfterNow(time, format) {
        const now = this.getFormattedTime(null, format);

        return moment(time).isSameOrAfter(now);
    }

    isSameOrBeforeNow(time, format) {
        const now = this.getFormattedTime(null, format);

        return moment(time).isSameOrBefore(now);
    }

    /**
     *  checks if a datetime supplied by the server (thus America/New_York timezone)
     *  is the same or before the browser's current time.
     *  To do this properly the browser's current time is first converted to server's timezone
     * and then compared with the datetime supplied by server.
     * @param {*} serverDateTime 
     * @param {*} format 
     * @returns 
     */
    isServerTimeSameOrBeforeNow(serverDateTime, format){
        const now = this.getFormattedTimeInServerTimezone(null, format);
        return moment(serverDateTime).isSameOrBefore(now);
    }

    getFormattedTime(time, format) {
        if (time) {
            return moment(time).format(format);
        } else {
            return moment().format(format);
        }
    }

    getFormattedTimeInServerTimezone(time, format) {
        if (time) {
            return moment(time).tz("America/New_York").format(format);
        } else {
            return moment().tz("America/New_York").format(format);
        }
    }

    addTime(time, amount, key) {
        return moment(time).add(amount, key)
    }

    getTimeDifference(timeA, timeB, measurement) {


        return moment(timeA).diff(moment(timeB), measurement) * -1;
    }
}

const TimeUtils = new TimeUtilsImpl();
export default TimeUtils;