import StatusImpressionDetector from './StatusImpressionDetector';

import APIService from './APIService';


class FeedServiceImpl {

    statusImpressionListener = (dataset) => { 
        try{
           
            let activityIdIndex = dataset.logId.indexOf('activityContent-')+"activityContent-".length;
            let activityId = dataset.logId.substring(activityIdIndex);
            let feedPostId = dataset.feedPostId;
            
            if( dataset.isOwnPost != "true" ) {
              
               
                let feedPostLook = {
                    feedPostId : feedPostId,
                    activityId : activityId,
                    
                }
                APIService.post('/feed/post/public/look', feedPostLook);
                
            }
        }catch( err) {
            console.warn("error detecting impression " + err);
        }
        
    };

    startStatusImpressionDetector(context) {//eslint-disable-line
        //console.log("startStatusImpressionDetector called from "+context)
        
        setTimeout( () => {
            
            StatusImpressionDetector.getInstance().stop();
           // console.log("setting up impression detector from call context "+ context)
            StatusImpressionDetector.getInstance().start("impression-list", "activity-card-container", this.statusImpressionListener);
            
        }, 3000);
            
        
   }

   stopStatusImpressionDetector(context) {//eslint-disable-line
        //console.log("stop impression detector called from "+ context);
           
        
        StatusImpressionDetector.getInstance().stop();
       
   }

   recordPublicLook(activity) {
        let feedPostLook = {
            feedPostId : activity.foreign_id,
            activityId : activity.id,
            
        }
        APIService.post('/feed/post/public/look', feedPostLook);
   }
}

const FeedService = new FeedServiceImpl();
export default FeedService;