<template>
	<Dialog header="Account Needed" v-model:visible="display" :modal="true" class='account-needed-modal' @show='onShown'>
    You need a free account to get access to this part of the platform - please login or sign up!
		<template #footer>
      <a class="login-link" @click="$emit('to-login')">Login</a>
      <button class="signup-cta" @click="$emit('to-signup')">Sign Up</button>
		</template>
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

export default {
    name: 'AccountNeededModal',
    emits: ['to-login', 'to-signup'],
    data() {
		return {
			
			display: false
		}
    },

	components: {
		Dialog
	},

	methods: {
		open() {
			this.display = true;
		},

    onShown() {
        document.body.style.overflow = 'visible';
    },
	}

}
</script>

<style>
.account-needed-modal {
  font-family: "Trebuchet MS", "Verdana";
	box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  width: 340px;
}

.account-needed-modal .p-dialog-content {
  font-size: 16px;
  color: #32364E;
  padding: 16px;
}

.account-needed-modal .p-dialog-footer {
  padding: 16px;
  text-align: center;
}

.account-needed-modal .p-dialog-header {
        padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
       
.account-needed-modal .p-dialog-footer {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 16px;
    width: 100% !important;
}
.account-needed-modal .p-dialog-header .p-dialog-header-icon {
    display: block;
}  

.account-needed-modal .p-dialog-header .p-dialog-title {
    font-weight: bold;
    font-size: 24px;
    color: #32364e;
}

</style>
<style scoped>
* {
  font-family: "Trebuchet MS", "Verdana";
}

 .login-link {
    text-decoration: none;
    margin-right: 20px;
    font-size: 16px;
    color: #333;
    font-weight: 600;
}

.login-link:hover {
    cursor: pointer;
}

.signup-cta {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  background-image: linear-gradient(
    to right,
    #693bf5,
    rgb(105, 59, 245, 0.85),
    rgb(105, 59, 245, 0.7)
  );
  transition: all 0.1s ease-in;
}

.signup-cta:hover {
    box-shadow: 0 0.4rem 3.2rem rgb(105, 59, 245, 0.6);
    transform: scale(1.05);
    cursor: pointer;
}

.signup-cta:active {
    transform: scale(0.95);
}

</style>
