export const MOTION_STATUS_ID = {
    draft: 1,
    submitted: 2,
    withdrawn: 3,
    passed: 4,
    failed: 5,
    vetoed: 6
}

export const MOTION_STATUS = {
    [MOTION_STATUS_ID.draft]:'Draft',
    [MOTION_STATUS_ID.submitted]:'Submitted',
    [MOTION_STATUS_ID.withdrawn]:'Withdrawn',
    [MOTION_STATUS_ID.passed]:'Passed',
    [MOTION_STATUS_ID.failed]:'Failed',
    [MOTION_STATUS_ID.vetoed]:'Vetoed'
}
export const SUBMITTED_MOTION_STATUS = 'submitted';
export const DRAFT_MOTION_STATUS = 'draft';
export const WITHDRAWN_MOTION_STATUS = 'withdrawn';
export const PASSED_MOTION_STATUS = 'passed';
export const FAILED_MOTION_STATUS = 'failed';
export const VETOED_MOTION_STATUS = 'vetoed';

export const YESNO_POLL = { name:'yesNo', pollTypeId:1};
export const MULTIPLE_CHOICE_POLL= { name:'multipleChoice', pollTypeId:2};
export const MULTIPLE_SELECT_POLL= { name: 'multipleSelection', pollTypeId:3};