import numeral from 'numeral';


export default class FormattingUtils {
  
    constructor() {
        this.currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

        // Prevents excpetion error being thrown for trying to register a locale twice
        if (numeral.locales['invrs-en'] === undefined) { 
          numeral.register('locale', 'invrs-en', {
            delimiters: {
                thousands: ',',
                decimal: '.'
            },
            abbreviations: {
                thousand: 'K',
                million: 'M',
                billion: 'B',
                trillion: 'T'
            },
            ordinal: function(number) {
                var b = number % 10;
                return (~~(number % 100 / 10) === 1) ? 'th' :
                    (b === 1) ? 'st' :
                    (b === 2) ? 'nd' :
                    (b === 3) ? 'rd' : 'th';
            },
            currency: {
                symbol: '$'
            }
          });
        }
        
        numeral.locale('invrs-en');
    }
  
  format(number, value) {
    return numeral(number).format(value);
  }

  formatAsCurrency(value) {
    return this.currencyFormat.format(value);
  }
  
  formatSecurityStat(stat) {
    // Each valueType field has it's own numeric formatting that will be used respectively on the stat's value field
    const statFormatters = {
      'Millions': '$0.0a',
      'Ratio': '0.0',
      'Percent': '0.00%',
      'Decimal Percentage': '0.00%',
      '$ & cents': '$0,0.00',
      'Actual' : '0.0000',
      'Missing': '-',
      
    }

    let statFormat = statFormatters[stat.valueType];

    if (stat.valueType == 'Ratio') {
      return numeral(stat.value).format(statFormat) + 'x';
    } else if (stat.valueType == 'Actual') {
      if (stat.name === 'Average Volume') {
        return numeral(stat.value).format('0,0');
      } else if (stat.name === 'Dividend Yield') {
        return numeral(stat.value).format('0.00%');
      }
      return numeral(stat.value).format(statFormat);
      
    } else if (stat.valueType == 'Decimal Percentage') {
      return numeral(stat.value).divide(100).format(statFormat);

    } else if ( stat.valueType == 'Missing'){
      return '-';
    
    } else { // For value types of '$ & cents', Percent and Millions
      return numeral(stat.value).format(statFormat);
    }

  }
}
