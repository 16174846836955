


class StringUtilsImpl {

    stripHtml(html){
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    /* Gets rid of wierd random words being inserted at the end of the company name, etc. The */
    stripQMCompanyName(name) {
        if (name.endsWith('The')) {
            return name.substring(0, name.length - 3).trim();
        }

        return name;
    }

    fullName(names) {
        if( names) {
            const firstName = (names.firstName) ? names.firstName : '';
            const middleName = (names.middleName) ? names.middleName : '';
            const lastName = (names.lastName) ? names.lastName : '';

            return `${firstName} ${middleName} ${lastName}`
        }
        else {
            return '';
        }
    }

    stripNonPrintableCharacters(string) {
        const regex = /[^\uD83C-\uDFFFa-zA-Z0-9\=\!\?\;\:\$\#\&\^\@\*\%\,\'\"\<\>\+\(\)\.\-\_\`\~\(\)\[\]\/\{\}\|\\\s\u2026\u201C\u201D]/gm //eslint-disable-line

        return string.replace(regex, '');
    }

    isNotEmpty(str){
      return str && str.trim().length > 0;
    }

    convertXigniteToQmCompatibleSymbol(symbol) {
        return symbol.replace("/", ".");
    }

}

const StringUtils = new StringUtilsImpl();
export default StringUtils;